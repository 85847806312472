@import "variables";
@import '../../../../node_modules/antd/lib/style/color/colors.less';

/* color classes */

// BG ColorPicker

.@{class-prefix}-bg-primary-light {
  background-color: @primary-4 !important;
}

.@{class-prefix}-bg-secondary {
  background-color: @secondary-color !important;
}

a.@{class-prefix}-bg-secondary {
  &:hover,
  &:focus {
    background-color: @secondary-color !important;
  }
}

.@{class-prefix}-bg-success {
  background-color: @success-color !important;
}

a.@{class-prefix}-bg-success {
  &:hover,
  &:focus {
    background-color: darken(@success-color, 10%) !important;
  }
}

.@{class-prefix}-bg-success-dark {
  background-color: darken(@success-color, 15%) !important;
}

a.@{class-prefix}-bg-success-dark {
  &:hover,
  &:focus {
    background-color: darken(@success-color, 20%) !important;
  }
}

.@{class-prefix}-bg-info {
  background-color: @info-color !important;
}

a.@{class-prefix}-bg-info {
  &:hover,
  &:focus {
    background-color: darken(@info-color, 10%) !important;
  }
}

.@{class-prefix}-bg-warning {
  background-color: @warning-color !important;
}

a.@{class-prefix}-bg-warning {
  &:hover,
  &:focus {
    background-color: darken(@warning-color, 10%) !important;
  }
}

.@{class-prefix}-bg-danger {
  background-color: @danger-color !important;
}

a.@{class-prefix}-bg-danger {
  &:hover,
  &:focus {
    background-color: darken(@danger-color, 10%) !important;
  }
}

.@{class-prefix}-bg-light {
  background-color: @grey-4 !important;
}

a.@{class-prefix}-bg-light {
  &:hover,
  &:focus {
    background-color: @grey-5 !important;
  }
}

.@{class-prefix}-bg-dark {
  background-color: @black-color !important;
}

a.@{class-prefix}-bg-dark {
  &:hover,
  &:focus {
    background-color: lighten(@black-color, 10%) !important;
  }
}

.@{class-prefix}-bg-white {
  background-color: @white-color !important;
}

a.@{class-prefix}-bg-white {
  &:hover,
  &:focus {
    background-color: darken(@white-color, 5%) !important;
  }
}

.@{class-prefix}-bg-grey {
  background-color: darken(@grey-5, 5%) !important;
}

a.@{class-prefix}-bg-grey {
  &:hover,
  &:focus {
    background-color: darken(@grey-6, 5%) !important;
  }
}

.@{class-prefix}-bg-light-grey {
  background-color: @grey-2 !important;
}

.@{class-prefix}-bg-transparent {
  background-color: transparent !important;
}

.@{class-prefix}-bg-pink {
  background-color: @pink-color !important;
}

a.@{class-prefix}-bg-pink {
  &:hover,
  &:focus {
    background-color: darken(@pink-color, 10%) !important;
  }
}

.@{class-prefix}-bg-green {
  background-color: @green-color !important;
}

a.@{class-prefix}-bg-green {
  &:hover,
  &:focus {
    background-color: darken(@green-color, 10%) !important;
  }
}

.@{class-prefix}-bg-green-light {
  background-color: lighten(@green-color, 40%) !important;
}

a.@{class-prefix}-bg-green-light {
  &:hover,
  &:focus {
    background-color: lighten(@green-color, 20%) !important;
  }
}

.@{class-prefix}-bg-red {
  background-color: @red-color !important;
}

a.@{class-prefix}-bg-red {
  &:hover,
  &:focus {
    background-color: darken(@red-color, 10%) !important;
  }
}

.@{class-prefix}-bg-amber {
  background-color: @amber-color !important;
}

a.@{class-prefix}-bg-amber {
  &:hover,
  &:focus {
    background-color: darken(@amber-color, 10%) !important;
  }
}

.@{class-prefix}-bg-amber-light {
  background-color: lighten(@amber-color, 30%) !important;
}

a.@{class-prefix}-bg-amber-light {
  &:hover,
  &:focus {
    background-color: lighten(@amber-color, 20%) !important;
  }
}

.@{class-prefix}-bg-blue {
  background-color: @blue-color !important;
}

a.@{class-prefix}-bg-blue {
  &:hover,
  &:focus {
    background-color: darken(@blue-color, 10%) !important;
  }
}

.@{class-prefix}-bg-light-blue {
  background-color: lighten(@blue-color, 5%) !important;
}

a.@{class-prefix}-bg-light-blue {
  &:hover,
  &:focus {
    background-color: lighten(@blue-color, 10%) !important;
  }
}

.@{class-prefix}-bg-indigo {
  background-color: @indigo-color !important;
}

a.@{class-prefix}-bg-indigo {
  &:hover,
  &:focus {
    background-color: darken(@indigo-color, 10%) !important;
  }
}

.@{class-prefix}-bg-purple {
  background-color: @purple-color !important;
}

a.@{class-prefix}-bg-purple {
  &:hover,
  &:focus {
    background-color: darken(@purple-color, 10%) !important;
  }
}

.@{class-prefix}-bg-orange {
  background-color: @orange-color !important;
}

a.@{class-prefix}-bg-orange {
  &:hover,
  &:focus {
    background-color: darken(@orange-color, 10%) !important;
  }
}

.@{class-prefix}-bg-yellow {
  background-color: @yellow-color !important;
}

a.@{class-prefix}-bg-yellow {
  &:hover,
  &:focus {
    background-color: darken(@yellow-color, 10%) !important;
  }
}

.@{class-prefix}-bg-teal {
  background-color: @teal-color !important;
}

a.@{class-prefix}-bg-teal {
  &:hover,
  &:focus {
    background-color: darken(@teal-color, 10%) !important;
  }
}

.@{class-prefix}-bg-cyan {
  background-color: @cyan-color !important;
}

a.@{class-prefix}-bg-cyan {
  &:hover,
  &:focus {
    background-color: darken(@cyan-color, 10%) !important;
  }
}

.@{class-prefix}-bg-sepia {
  background-color: @sepia-color !important;
}

a.@{class-prefix}-bg-sepia {
  &:hover,
  &:focus {
    background-color: darken(@sepia-color, 10%) !important;
  }
}

.@{class-prefix}-bg-geekblue {
  background-color: @geekblue-10 !important;
}

a.@{class-prefix}-bg-geekblue {
  &:hover,
  &:focus {
    background-color: darken(@geekblue-10, 10%) !important;
  }
}

// Text ColorPicker
.@{class-prefix}-text-white {
  color: @white-color !important;
}

.@{class-prefix}-text-secondary {
  color: @secondary-color !important;
}

a.@{class-prefix}-text-secondary {
  &:hover,
  &:focus {
    color: @secondary-color !important;
  }
}

.@{class-prefix}-text-success {
  color: @success-color !important;
}

a.@{class-prefix}-text-success {
  &:hover,
  &:focus {
    color: darken(@success-color, 5%) !important;
  }
}

.@{class-prefix}-text-success-dark {
  color: darken(@success-color, 15%) !important;
}

a.@{class-prefix}-text-success-dark {
  &:hover,
  &:focus {
    color: darken(@success-color, 20%) !important;
  }
}

.@{class-prefix}-text-info {
  color: @info-color !important;
}

a.@{class-prefix}-text-info {
  &:hover,
  &:focus {
    color: darken(@info-color, 5%) !important;
  }
}

.@{class-prefix}-text-warning {
  color: @warning-color !important;
}

a.@{class-prefix}-text-warning {
  &:hover,
  &:focus {
    color: darken(@warning-color, 5%) !important;
  }
}

.@{class-prefix}-text-danger {
  color: @danger-color !important;
}

a.@{class-prefix}-text-danger {
  &:hover,
  &:focus {
    color: darken(@danger-color, 5%) !important;
  }
}

.@{class-prefix}-text-light {
  color: darken(@grey-6, 8%) !important;
}

a.@{class-prefix}-text-light {
  &:hover,
  &:focus {
    color: darken(@grey-7, 8%) !important;
  }
}

.@{class-prefix}-text-light-grey {
  color: darken(@grey-5, 5%) !important;
}

a.@{class-prefix}-text-light-grey {
  &:hover,
  &:focus {
    color: @grey-6 !important;
  }
}

.@{class-prefix}-text-grey {
  color: @grey-7 !important;
}

a.@{class-prefix}-text-grey {
  &:hover,
  &:focus {
    color: @grey-9 !important;
  }
}

.@{class-prefix}-text-dark {
  color: @grey-8 !important;
}

a.@{class-prefix}-text-dark {
  &:hover,
  &:focus {
    color: darken(@grey-8, 5%) !important;
  }
}

.@{class-prefix}-text-black {
  color: @black-color !important;
}

a.@{class-prefix}-text-black {
  &:hover,
  &:focus {
    color: darken(@grey-8, 5%) !important;
  }
}

.@{class-prefix}-text-pink {
  color: @pink-color !important;
}

a.@{class-prefix}-text-pink {
  &:hover,
  &:focus {
    color: darken(@pink-color, 5%) !important;
  }
}

.@{class-prefix}-text-green {
  color: @green-color !important;
}

a.@{class-prefix}-text-green {
  &:hover,
  &:focus {
    color: darken(@green-color, 5%) !important;
  }
}

.@{class-prefix}-text-red {
  color: @red-color !important;
}

a.@{class-prefix}-text-red {
  &:hover,
  &:focus {
    color: darken(@red-color, 5%) !important;
  }
}

.@{class-prefix}-text-amber {
  color: @amber-color !important;
}

a.@{class-prefix}-text-amber {
  &:hover,
  &:focus {
    color: darken(@amber-color, 5%) !important;
  }
}

.@{class-prefix}-text-blue {
  color: @blue-color !important;
}

a.@{class-prefix}-text-blue {
  &:hover,
  &:focus {
    color: darken(@blue-color, 5%) !important;
  }
}

.@{class-prefix}-text-indigo {
  color: @indigo-color !important;
}

a.@{class-prefix}-text-indigo {
  &:hover,
  &:focus {
    color: darken(@indigo-color, 5%) !important;
  }
}

.@{class-prefix}-text-purple {
  color: @purple-color !important;
}

a.@{class-prefix}-text-purple {
  &:hover,
  &:focus {
    color: darken(@purple-color, 5%) !important;
  }
}

.@{class-prefix}-text-orange {
  color: @orange-color !important;
}

a.@{class-prefix}-text-orange {
  &:hover,
  &:focus {
    color: darken(@orange-color, 5%) !important;
  }
}

.@{class-prefix}-text-yellow {
  color: @yellow-color !important;
}

a.@{class-prefix}-text-yellow {
  &:hover,
  &:focus {
    color: darken(@yellow-color, 5%) !important;
  }
}

.@{class-prefix}-text-teal {
  color: @teal-color !important;
}

a.@{class-prefix}-text-teal {
  &:hover,
  &:focus {
    color: darken(@teal-color, 5%) !important;
  }
}

.@{class-prefix}-text-cyan {
  color: @cyan-color !important;
}

a.@{class-prefix}-text-cyan {
  &:hover,
  &:focus {
    color: darken(@cyan-color, 5%) !important;
  }
}

.@{class-prefix}-text-muted {
  color: @grey-7 !important;
}

.@{class-prefix}-text-geekblue {
  color: @geekblue-10 !important;
}

a.@{class-prefix}-text-geekblue {
  &:hover,
  &:focus {
    color: @geekblue-8 !important;
  }
}

.@{class-prefix}-text-sepia {
  color: @sepia-color !important;
}

a.@{class-prefix}-text-sepia {
  &:hover,
  &:focus {
    color: @sepia-color !important;
  }
}

.@{class-prefix}-icon-white {
  color: @white-color !important;
}


// Badge ColorPicker
.@{class-prefix}-badge-secondary {
  background-color: @secondary-color !important;
}

.@{class-prefix}-badge-success {
  background-color: @success-color !important;
}

.@{class-prefix}-badge-success-dark {
  background-color: darken(@success-color, 15%) !important;
}

.@{class-prefix}-badge-info {
  background-color: @info-color !important;
}

.@{class-prefix}-badge-warning {
  background-color: @warning-color !important;
}

.@{class-prefix}-badge-danger {
  background-color: @danger-color !important;
}

.@{class-prefix}-badge-light {
  background-color: @grey-4 !important;
}

.@{class-prefix}-badge-dark {
  background-color: @black-color !important;
}

.@{class-prefix}-badge-white {
  background-color: @white-color !important;
}

.@{class-prefix}-badge-grey {
  background-color: @grey-6 !important;
}

.@{class-prefix}-badge-light-grey {
  background-color: @grey-5 !important;
}

.@{class-prefix}-badge-pink {
  background-color: @pink-color !important;
}

.@{class-prefix}-badge-green {
  background-color: @green-color !important;
}

.@{class-prefix}-badge-green-light {
  background-color: lighten(@green-color, 40%) !important;
}

.@{class-prefix}-badge-red {
  background-color: @red-color !important;
}

.@{class-prefix}-badge-amber {
  background-color: @amber-color !important;
}

.@{class-prefix}-badge-amber-light {
  background-color: lighten(@amber-color, 30%) !important;
}

.@{class-prefix}-badge-blue {
  background-color: @blue-color !important;
}

.@{class-prefix}-badge-light-blue {
  background-color: lighten(@blue-color, 5%) !important;
}

.@{class-prefix}-badge-indigo {
  background-color: @indigo-color !important;
}

.@{class-prefix}-badge-purple {
  background-color: @purple-color !important;
}

.@{class-prefix}-badge-orange {
  background-color: @orange-color !important;
}

.@{class-prefix}-badge-yellow {
  background-color: @yellow-color !important;
}

.@{class-prefix}-badge-teal {
  background-color: @teal-color !important;
}

.@{class-prefix}-badge-cyan {
  background-color: @cyan-color !important;
}

.@{class-prefix}-badge-sepia {
  background-color: @sepia-color !important;
}

.@{class-prefix}-badge-geekblue {
  background-color: @geekblue-10 !important;
}







